import React from "react";
import { graphql, Link } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { buildImageObj, mapEdgesToNodes } from "../lib/helpers";
import HeroSection from "../components/hero-section/hero-section";
import SolutionKeyFeatures from "../components/solution-key-features/solution-key-features";
import Faq from "../components/faq/faq";
import BlockContent from "../components/block-content";
import loadable from "@loadable/component";
import { imageUrlFor } from "../lib/image-url";

const VideoComponent = loadable(() => import("../components/video/axeptia-video"));
const HubspotFormComponent = loadable(() => import("../components/hubspot-form/hubspot-form"));

export const query = graphql`
  query SolutionPageQuery {
    site: sanitySolutionPage {
      subtitle
      title
      seo
      keywords
      _rawDescription
      id
      mainImage {
        asset {
          _id
        }
        alt
      }
    }
    faqs: allSanityFaq {
      edges {
        node {
          _rawDescription
          slug {
            current
          }
          title
        }
      }
    }
  }
`;

const SolutionPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const faqs = data && data.faqs && mapEdgesToNodes(data.faqs);
  const imageUrl = imageUrlFor(buildImageObj(site.mainImage))
    .width(600)
    .width(600)
    .fit("fill")
    .url();

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.seo}
        keywords={site.keywords}
        lang={site.title}
        image={imageUrl}
      />
      <HeroSection>
        <div>
          {site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}
          <Link to="/get-started#get-started-form" className="primary-link-btn">
            I am ready to book a demo
          </Link>
        </div>
        <div
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <VideoComponent
            controls
            autoPlay
            muted
            loop
            width={733}
            height={409}
            videoSrc={"/axeptia-solution-video.mp4"}
            mobilePoster={"/solution.png"}
          ></VideoComponent>
        </div>
      </HeroSection>
      <Container backgroundColor="white" role="complementary">
        <SolutionKeyFeatures></SolutionKeyFeatures>
      </Container>
      <Container role="complementary" id="newsletter-form">
        <HubspotFormComponent
          title="Subscribe to our newsletter"
          portalId={5841642}
          formId="57391973-0e04-41aa-b72a-0602256c3305"
          region="na1"
        ></HubspotFormComponent>
      </Container>
      {faqs && faqs.lenght && (
        <Container backgroundColor="white" role="complementary">
          <Faq faqs={faqs}></Faq>
        </Container>
      )}
    </Layout>
  );
};

export default SolutionPage;
