import React from "react";

import * as styles from "./solution-key-features.module.scss";
import { buildImageObj, mapEdgesToNodes } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import { graphql, StaticQuery } from "gatsby";
import Image from "../image/image";

const SolutionKeyFeatures = () => (
  <StaticQuery
    query={graphql`
      query SolutionKeyFeaturesQuery {
        solutionKeyFeatures: allSanitySolutionKeyFeatures {
          edges {
            node {
              _id
              mainImage {
                asset {
                  _id
                }
                alt
              }
              title
              description
            }
          }
        }
      }
    `}
    render={data => {
      const features =
        data && data.solutionKeyFeatures && mapEdgesToNodes(data.solutionKeyFeatures);
      if (features) {
        return (
          <ul className={styles.container}>
            {features.map((feature, i) => (
              <li
                key={`feature-${i}`}
                data-sal="slide-up"
                data-sal-delay={i * 100 + ""}
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <div className={styles.featureContainer}>
                  <div>
                    {feature.mainImage && feature.mainImage.asset && (
                      <Image
                        asset={feature.mainImage.asset}
                        width={50}
                        height={50}
                        alt={feature.mainImage.alt}
                        fit={"fill"}
                      ></Image>
                    )}
                  </div>
                  <div>
                    <h3>{feature.title}</h3>
                    <span>{feature.description}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        );
      } else {
        return null;
      }
    }}
  />
);

export default SolutionKeyFeatures;
